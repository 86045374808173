import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <h1>Hello Cloudflare Pages</h1>
    </div>
  );
}

export default App;
